import React, { Component } from "react";
import RemarkableTreesMap from "./remarkableTreesMap";

class Explore extends Component {
  render() {
    return (
      <div>
        <RemarkableTreesMap />
      </div>
    );
  }
}

export default Explore;
