import React, { Component } from 'react';
import './App.css';

class NotFound extends Component {
  render() {
    return (
      <div className="App">
        <div className="App-header">
          <h1>404</h1>
        </div>
        <p>Wow c'est la hess ici ou quoi ?</p>
      </div>
    );
  }
}

export default NotFound;